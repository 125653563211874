<template>
  <div id="main-container" :key="componentKey">

    <!-- Content-->
    <div class="custom-row-element-wrap pt-5 mb-5">

      <div v-if="visibleRecords != null" class="mr-4 ml-4">
        <div class="row align-items-center" v-if="visible == 4">
          <div class="col-lg-12" style="text-align: center; font-size: large;">
          <p>{{ $t('Main.Disputesandconflicts') }}</p>
          </div>
        </div>
        <div v-for="data in visibleRecords" :key="data.id" class="row align-items-center mb-5">
          <div class="col-lg-4">
            <div v-if="data.image != null">
              <div >
                <img :src="url+data.image" class="custom-post-large-image" style="width: 14rem;
    height: 15rem;
    margin-left: 35px; ">
              </div>

            </div>





































            
            <div v-else>
              <div >
                <img src="../../assets/images/no-image.png" class="custom-post-large-image">
              </div>

            </div>
          </div>
          <div v-if="urdudata == false" class="col-lg-8">
            <div class="post-container">
              <div class=" mt-2 mb-4 border-bottom ">
<!--                <h5 class="text-uppercase custom-text-color">We Provide Best</h5>-->
                <h2 class=" widget-title custom-text-color text-uppercase">{{ data.title }}</h2>
              </div>
              <div>
                <p class="custom-service-text text-justify">
                  {{ data.description }}
                </p>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                        <span>
                          <router-link
                            :to="{ name: 'Detail',
                               params: { id: data.id }
                            }"
                            class="text-decoration-none custom-ellipsis-icon post-categories-link">
                        <span>
                          Read More
                          <!--                          Category-->
                        </span>
                      </router-link>
                        </span>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-8">
            <div class="post-container">
              <div class=" mt-2 mb-4 border-bottom ">
<!--                <h5 class="text-uppercase custom-text-color">We Provide Best</h5>-->
                <h2 style="display: flex;" class=" widget-title custom-text-color text-uppercase">{{ data.urduTitle }}</h2>
              </div>
              <div>
                <p class="custom-service-text text-justify">
                  {{ data.urduDescription }}
                </p>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                        <span>
                          <router-link
                            :to="{ name: 'Detail',
                               params: { id: data.id }
                            }"
                            class="text-decoration-none custom-ellipsis-icon post-categories-link">
                        <span>
                          {{ $t('Main.ReadMore') }}
                          <!--                          Category-->
                        </span>
                      </router-link>
                        </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--   Spacer   -->
      <div class="pt-5"></div>

      <div class="text-center clear-bottom">
        <div class="row no-gutters ">
          <div class="col-lg-3 mt-1 mb-1"></div>
          <div class="col-lg-2 mt-1 mb-1" v-if="visibleRecords == null"></div>
          <div class="col-lg-2 mt-1 mb-1">
            <router-link :to="{ name: 'Home',hash: '#ourServices_id'
                            }"
                         class="btn link-theme text-decoration-none">Explore Services</router-link>
          </div>
          <div class="col-lg-2 mt-1 mb-1" v-if="visibleRecords != null">
            <!--  View More Button    -->
            <div >
              <div v-if="recordsVisible < record.length"  >
                <div class="text-center clear-bottom">
                  <a href="javascript:void(0)"
                     @click="recordsVisible += step"
                     class="btn link-theme text-decoration-none">
                    View More
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-2 mt-1 mb-1">
            <router-link :to="{ name: 'ContactUs' }"

                         class="btn link-theme text-decoration-none">Contact Us</router-link>
          </div>

          <div class="col-lg-3 mt-1 mb-1"></div>
        </div>

      </div>

    </div>

    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!--  Navigate To services    -->
<!--    <div class="text-center  mb-5">
      <div class="custom-widget mt-2 ">
        <h2 class=" ">Our Services</h2>
      </div>
      <div class="custom-row justify-content-center">
        <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
&lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
            <b-icon icon="trophy"></b-icon>
          </span>
        <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
      </div>

    </div>
    <div>
      <div class="text-center clear-bottom">
        <router-link :to="{ name: 'Home',hash: '#ourServices_id'
                            }"

                     class="btn link-theme text-decoration-none">Explore Services</router-link>
      </div>
    </div>-->
    <!--   Spacer   -->
    <div class="pt-5"></div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'PartnersAndFunding',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    UsersIcon
  },
  data () {
    return {

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      visible: 0,

      model: {
        filter: {
          PageSize: 0,
          PageNumber: 1,
          Text: ''
        },
        articleType: {
          type: 0
        }
      },
      // list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      isShowMoreBtnVisible: false,
      recordsVisible: 5,
      step: 5,
      urdudata:false,
      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getAll () {
      console.log('id : ', this.$route.params.type)
      this.visible = this.$route.params.type
      console.log('searchString : ', this.$route.params.searchString)

      if (this.$route.params.type != 0) {
        this.model.articleType.type = Number(this.$route.params.type)
        if (this.$route.params.searchString != '0') {
          this.model.filter.Text = this.$route.params.searchString
          console.log(this.model.filter.Text)
        } else {
          this.model.filter.Text = ''
        }
      } else {
        this.model.articleType.type = 0
        if (this.$route.params.searchString != '0') {
          this.model.filter.Text = this.$route.params.searchString
          console.log(this.model.filter.Text)
        } else {
          this.model.filter.Text = ''
        }
      }

      console.log('model : ', this.model)
      this.$store.dispatch('fetchPartnersAndFunding', this.model)

    },

    showMore(){
      this.model.filter.PageSize += 5;
      this.getAll();
    },
    // Rerender Component
    rerender () {
      this.componentKey += 1
    }
  },

  /* beforeCreate () {
    this.$nextTick(() => {
      this.getAll();
    });
  }, */
  beforeCreate () {
    return this.$options.computed.visibleRecords
  },
  computed: {
    record () {
      // if(this.$route.params.type)
      console.log('Available record', this.$store.getters.availableRecords)
      return this.$store.getters.availableRecords
    },
    visibleRecords () {
      return this.record.slice(0, this.recordsVisible)
    }
    /* totalCount () {
      console.log("total Count: ", this.$store.getters.totalCount)
      return this.$store.getters.totalCount;
    } */
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        debugger
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  mounted () {
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    this.getAll();
  }
}
</script>
