var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,attrs:{"id":"main-container"}},[_c('div',{staticClass:"custom-row-element-wrap pt-5 mb-5"},[(_vm.visibleRecords != null)?_c('div',{staticClass:"mr-4 ml-4"},[(_vm.visible == 4)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-12",staticStyle:{"text-align":"center","font-size":"large"}},[_c('p',[_vm._v(_vm._s(_vm.$t('Main.Disputesandconflicts')))])])]):_vm._e(),_vm._l((_vm.visibleRecords),function(data){return _c('div',{key:data.id,staticClass:"row align-items-center mb-5"},[_c('div',{staticClass:"col-lg-4"},[(data.image != null)?_c('div',[_c('div',[_c('img',{staticClass:"custom-post-large-image",staticStyle:{"width":"14rem","height":"15rem","margin-left":"35px"},attrs:{"src":_vm.url+data.image}})])]):_c('div',[_vm._m(0,true)])]),(_vm.urdudata == false)?_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"mt-2 mb-4 border-bottom"},[_c('h2',{staticClass:"widget-title custom-text-color text-uppercase"},[_vm._v(_vm._s(data.title))])]),_c('div',[_c('p',{staticClass:"custom-service-text text-justify"},[_vm._v(" "+_vm._s(data.description)+" ")])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-10"}),_c('div',{staticClass:"col-md-2"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none custom-ellipsis-icon post-categories-link",attrs:{"to":{ name: 'Detail',
                               params: { id: data.id }
                            }}},[_c('span',[_vm._v(" Read More ")])])],1)])])]):_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"mt-2 mb-4 border-bottom"},[_c('h2',{staticClass:"widget-title custom-text-color text-uppercase",staticStyle:{"display":"flex"}},[_vm._v(_vm._s(data.urduTitle))])]),_c('div',[_c('p',{staticClass:"custom-service-text text-justify"},[_vm._v(" "+_vm._s(data.urduDescription)+" ")])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-10"}),_c('div',{staticClass:"col-md-2"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none custom-ellipsis-icon post-categories-link",attrs:{"to":{ name: 'Detail',
                               params: { id: data.id }
                            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.ReadMore'))+" ")])])],1)])])])])})],2):_vm._e(),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"text-center clear-bottom"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-3 mt-1 mb-1"}),(_vm.visibleRecords == null)?_c('div',{staticClass:"col-lg-2 mt-1 mb-1"}):_vm._e(),_c('div',{staticClass:"col-lg-2 mt-1 mb-1"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'Home',hash: '#ourServices_id'
                            }}},[_vm._v("Explore Services")])],1),(_vm.visibleRecords != null)?_c('div',{staticClass:"col-lg-2 mt-1 mb-1"},[_c('div',[(_vm.recordsVisible < _vm.record.length)?_c('div',[_c('div',{staticClass:"text-center clear-bottom"},[_c('a',{staticClass:"btn link-theme text-decoration-none",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.recordsVisible += _vm.step}}},[_vm._v(" View More ")])])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-lg-2 mt-1 mb-1"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'ContactUs' }}},[_vm._v("Contact Us")])],1),_c('div',{staticClass:"col-lg-3 mt-1 mb-1"})])])]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"pt-5"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"custom-post-large-image",attrs:{"src":require("../../assets/images/no-image.png")}})])
}]

export { render, staticRenderFns }